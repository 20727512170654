import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Swal from 'sweetalert2';



export function toasterrormsg(message) {
    toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
    });
}

export function toastsuccessmsg(message) {
    toast.success(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
}


export const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY hh:mm A");
}

// blood group list



// ------------------------- change react select style on theme mode change ---------------------- //

// delete record
export const deleteRecords = () => {
    return Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete the record?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary"
        },
        buttonsStyling: false
    })
}

export const formatNumber = (number) => {
    const [integerPart, decimalPart] = number.toString().split(".");
  
    // Use regex to handle Indian numbering format (first group 3 digits, then every 2 digits)
    const formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+(\d)(?!\d))/g, "$1,");
  
    // Combine integer part and decimal part (if present)
    return decimalPart ? `${formattedInteger}.${decimalPart.slice(0, 2)}` : formattedInteger;
};
  
