import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { motion } from "framer-motion";
import { apiHeader, postData } from "../util/ApiHelper";
import { toasterrormsg, toastsuccessmsg } from "../util/reusable";
import { ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import locationImage from "../assets/images/connect_withus_page/location.png";
import phoneImage from "../assets/images/connect_withus_page/phone.png";
import gmailImage from "../assets/images/connect_withus_page/gmail.png";
import rangoliStoreImage from "../assets/images/connect_withus_page/rangolistore.png";

const ConnectWithUsWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const recaptcha = useRef();

  const [error, setError] = useState([
    {
      fullName: "",
      mobileNumber: "",
      email: "",
      captcha: "",
    },
  ]);

  const validate = () => {
    var isValid = true;
    var errorTmp = [...error];
    const emailValidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (
      fullName.trim() == "" ||
      fullName.trim() == null ||
      fullName.trim() == undefined
    ) {
      errorTmp[0].fullName = "Please Enter Full Name";
      isValid = false;
    }
    if (
      mobileNumber.trim() == "" ||
      mobileNumber.trim() == null ||
      mobileNumber.trim() == undefined
    ) {
      errorTmp[0].mobileNumber = "Please Enter Mobile Number";
      isValid = false;
    }
    if (
      mobileNumber != "" &&
      mobileNumber != null &&
      mobileNumber != undefined
    ) {
      if (!phoneRegex.test(mobileNumber)) {
        errorTmp[0].mobileNumber = "Please Enter a Valid Mobile Number";
        isValid = false;
      }
    }
    if (email != "" && email != null && email != undefined) {
      if (!emailValidate.test(email)) {
        errorTmp[0].email = "Entered Email is Not Valid";
        isValid = false;
      }
    }

    setError(errorTmp);
    return isValid;
  };

  const connectwithusform = async () => {
    var errorTmp = [...error];
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      errorTmp[0].captcha = "Please Verify That You are a Human";
    } else {
      errorTmp[0].captcha = "";
    }

    if (validate()) {
      setIsLoading(true);
      if (captchaValue) {
        setIsLoading(true);

        var param = {
          fullName: fullName,
          mobileNumber: mobileNumber,
        };
        if (email != "") {
          param.email = email;
        }
        if (additionalMessage != "") {
          param.additionalMessage = additionalMessage;
        }

        var response = await postData(
          "/connectwithusform",
          param,
          apiHeader(false)
        );
        if (
          String(response?.status) == "200" &&
          String(response.data.status) == "200"
        ) {
          setIsLoading(false);

          toastsuccessmsg(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          setIsLoading(false);
          toasterrormsg(response.data.errorMessage);
        }
      } else {
        setIsLoading(false);
      }
    } else {
    }
  };

  // remove validation function
  const removeError = (err) => {
    var tmp = [...error];
    tmp[0][err] = "";
    setError(tmp);
  };

  useEffect(() => {
    // Set a timeout to change the visibility after 3 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const textVariants = {
    hidden: {
      x: "-200px", // Start from far left (outside viewport)
      opacity: 0, // Hidden initially
    },
    visible: {
      x: 0, // Slide into the original position
      opacity: 1, // Become fully visible
      transition: {
        type: "tween", // Smooth tween transition
        duration: 2, // 2 seconds duration for a slow slide
        ease: "easeOut", // Smooth ease-out effect
      },
    },
  };

  const variants2 = {
    hidden: { opacity: 0, x: -200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants4 = {
    hidden: { opacity: 0, x: 200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-center main_title_news wow animate__animated animate__fadeInDown">
        <p className="text-center new_arrival_title">Contact Us</p>
      </div>

      {isVisible ? (
        <div className="gmail_call_section ">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="call_section call_section_factory"
          >
            <div className="call_image">
              <motion.img
                initial="hidden"
                whileHover={{ scale: 1.1 }}
                whileInView="visible"
                viewport={{ once: true }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants5}
                src={locationImage}
                alt="Location Marker Image"
              />
            </div>
            <div className="gmail_text">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="title mb-0 pb-0"
                >
                  Our Office
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="data"
                >
                  shukan mall, 624, 6th Floor, I Square Corporate Park, Science
                  City Rd, Science City, Panchamrut Bunglows II, Sola,
                  Ahmedabad, Gujarat 380060
                </motion.p>
              </div>
            </div>
          </motion.div>
        </div>
      ) : (
        ""
      )}
      {isVisible ? (
        <div className="gmail_call_section ">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants4}
            className="gmail_section"
          >
            <div className="gmail_image">
              <motion.img
                initial="hidden"
                whileHover={{ scale: 1.1 }}
                whileInView="visible"
                viewport={{ once: true }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants5}
                src={locationImage}
                alt="Location Marker Image"
              />
            </div>
            <div className="gmail_text">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="title mb-0 pb-0"
                >
                  Our Factory
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="data"
                >
                  Upleta Rd, Dhoraji, Gujarat 360410{" "}
                </motion.p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="call_section"
          >
            <div className="call_image">
              <motion.img
                initial="hidden"
                whileHover={{ scale: 1.1 }}
                whileInView="visible"
                viewport={{ once: true }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants5}
                src={phoneImage}
                alt="Call Photo"
              />
            </div>
            <div className="gmail_text">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="title mb-0 pb-0"
                >
                  Customer Care
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="data"
                >
                  <a
                    className="text-decoration-none"
                    href="tel:+91 95122 31112"
                  >
                    95122 31112
                  </a>
                </motion.p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants4}
            className="gmail_section"
          >
            <div className="gmail_image">
              <motion.img
                initial="hidden"
                whileHover={{ scale: 1.1 }}
                whileInView="visible"
                viewport={{ once: true }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants5}
                src={gmailImage}
                alt="Gmail Photo"
              />
            </div>
            <div className="gmail_text">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="title mb-0 pb-0"
                >
                  Customer Feedback
                </motion.p>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="data"
                >
                  <a
                    className="text-decoration-none"
                    href="mailto:feedback@rangolifoods.in"
                  >
                    feedback@rangolifoods.in
                  </a>
                </motion.p>
              </div>
            </div>
          </motion.div>
        </div>
      ) : (
        ""
      )}

      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          <div className="map-section col-lg-6 col-md-12">
            <div className=" d-flex justify-content-center align-items-center">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants5}
                className="map d-flex justify-content-center align-items-center"
              >
                <motion.img
                  src={rangoliStoreImage}
                  width={"100%"}
                  alt="Rangoli Store Image"
                />
              </motion.div>
            </div>
          </div>

          {isVisible ? (
            <div className="container col-lg-6  col-md-12 ">
              {isVisible ? (
                <div className="text-center question_form_title">
                  {/* Title comes from the left */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={variants2}
                  >
                    <motion.p
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={animationVariants}
                      whileHover="hover"
                      className="text-center new_arrival_title"
                    >
                      Have Question
                    </motion.p>
                  </motion.div>

                  {/* Subtitle comes from the right */}
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={variants5}
                  >
                    <motion.p
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={animationVariants}
                      className="sub_title_form"
                    >
                      For all inquiries, please contact us and one of our
                      delightful team will be happy to help.
                    </motion.p>
                  </motion.div>
                </div>
              ) : (
                ""
              )}

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={variants5}
                className="row"
              >
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="col-lg-12"
                >
                  <input
                    type="text"
                    className="w-100  connect_form_input"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      removeError("fullName");
                    }}
                  />
                  <div className="text-danger  fs-5 mt-1 px-3">
                    {error[0].fullName}
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="col-lg-6"
                >
                  <input
                    type="text"
                    className="w-100 connect_form_input"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      removeError("email");
                    }}
                  />
                  <div className="text-danger  fs-5 mt-1 px-3">
                    {error[0].email}
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="col-lg-6"
                >
                  <input
                    type="text"
                    className="w-100 connect_form_input"
                    placeholder="Phone Number"
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      removeError("mobileNumber");
                    }}
                  />
                  <div className="text-danger  fs-5 mt-1 px-3">
                    {error[0].mobileNumber}
                  </div>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="col-lg-12"
                >
                  <textarea
                    type="text"
                    className="w-100 connect_form_textarea pt-5"
                    cols={12}
                    rows={10}
                    placeholder="Message"
                    onChange={(e) => {
                      setAdditionalMessage(e.target.value);
                    }}
                  >
                    {additionalMessage}
                  </textarea>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  className="col-lg-12 mt-4"
                >
                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />
                </motion.div>
                <div className="text-danger  fs-5 mt-1 px-3">
                  {error[0].captcha}
                </div>
              </motion.div>

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.8 * 0.2,
                  type: "spring",
                  stiffness: 200,
                  damping: 10,
                }}
                variants={{
                  visible: { opacity: 1, scale: 1, y: 0 },
                  hidden: { opacity: 0, scale: 0.5, y: 100 },
                }}
                className="form_button"
              >
                <motion.button
                  onClick={() => {
                    connectwithusform();
                  }}
                  style={{ backgroundColor: isLoading == true ? "pink" : "" }}
                >
                  Send Message
                </motion.button>
              </motion.div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

const ConnectWthUs = () => {
  return <Header element={<ConnectWithUsWrapper />} />;
};

export { ConnectWthUs };
