import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const animationControls = useAnimation();

    // Preload images
    useEffect(() => {
        images.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, [images]);

    // Automatic slide change
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, [currentIndex]);

    // Reset animation when the currentIndex changes
    useEffect(() => {
        animationControls.set("hidden");
        animationControls.start("visible");
    }, [currentIndex, animationControls]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 === images.length ? 0 : prevIndex + 1
        );
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const slideVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.8, // Shorter duration for a snappier effect
                ease: [0.42, 0, 0.58, 1], // Smooth easing function (cubic-bezier)
            },
        },
    };

    return (
        <div className="carousel">
            <div className="carousel-images" style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <motion.img
                    src={images[currentIndex]}
                    initial="hidden"
                    animate={animationControls}
                    variants={slideVariants}
                    style={{ maxWidth: "100%", maxHeight: "100%" }} // Ensure image takes full space available
                />
            </div>
            <div className="carousel-indicator">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${currentIndex === index ? "active" : ""}`}
                        onClick={() => handleDotClick(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;