import React, { useEffect, useRef, useState } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Select from "react-select";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { toasterrormsg, toastsuccessmsg } from "../util/reusable";
import { apiHeader, postData } from "../util/ApiHelper";
import { ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const CareerWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [resumeUrl, setResumeUrl] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const recaptcha = useRef();

  const [error, setError] = useState([
    {
      fullName: "",
      mobileNumber: "",
      email: "",
      address: "",
      stateId: "",
      cityId: "",
      resume: "",
      captcha: "",
    },
  ]);

  const validate = () => {
    var isValid = true;
    var errorTmp = [...error];
    const emailValidate = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (
      fullName.trim() == "" ||
      fullName.trim() == null ||
      fullName.trim() == undefined
    ) {
      errorTmp[0].fullName = "Please Enter Full Name";
      isValid = false;
    }
    if (
      mobileNumber.trim() == "" ||
      mobileNumber.trim() == null ||
      mobileNumber.trim() == undefined
    ) {
      errorTmp[0].mobileNumber = "Please Enter Mobile Number";
      isValid = false;
    }
    if (
      mobileNumber != "" &&
      mobileNumber != null &&
      mobileNumber != undefined
    ) {
      if (!phoneRegex.test(mobileNumber)) {
        errorTmp[0].mobileNumber = "Please Enter a Valid Mobile Number";
        isValid = false;
      }
    }
    if (email != "" && email != null && email != undefined) {
      if (!emailValidate.test(email)) {
        errorTmp[0].email = "Entered Email is Not Valid";
        isValid = false;
      }
    }
    if (
      selectedState == "" ||
      selectedState == null ||
      selectedState == undefined
    ) {
      errorTmp[0].stateId = "Please Select a State";
      isValid = false;
    }
    if (
      selectedCity == "" ||
      selectedCity == null ||
      selectedCity == undefined
    ) {
      errorTmp[0].cityId = "Please Select a City";
      isValid = false;
    }
    if (address == "" || address == null || address == undefined) {
      errorTmp[0].address = "Please Enter Address";
      isValid = false;
    }
    if (resumeUrl == "" || resumeUrl == null || resumeUrl == undefined) {
      errorTmp[0].resume = "Please Select Your Resume";
      isValid = false;
    }

    setError(errorTmp);
    return isValid;
  };

  const carrerForm = async () => {
    var errorTmp = [...error];

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      errorTmp[0].captcha = "Please Verify That You are a Human";
    } else {
      errorTmp[0].captcha = "";
    }

    if (validate()) {
      if (captchaValue) {
        var param = new FormData();

        param.append("fullName", fullName);
        param.append("email", email);
        param.append("mobileNumber", mobileNumber);
        param.append("stateId", JSON.stringify(selectedState));
        param.append("cityId", JSON.stringify(selectedCity));
        param.append("address", address);
        param.append("resume", resumeUrl);

        var response = await postData("/careerform", param, apiHeader(true));
        if (
          String(response?.status) == "200" &&
          String(response.data.status) == "200"
        ) {
          toastsuccessmsg(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toasterrormsg(response.data.errorMessage);
        }
      } else {
      }
    } else {
    }
  };

  // remove validation function
  const removeError = (err) => {
    var tmp = [...error];
    tmp[0][err] = "";
    setError(tmp);
  };

  useEffect(() => {
    // Set a timeout to change the visibility after 3 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const stateListApiCall = async () => {
    var response = await postData("/statelist", {}, apiHeader(false));
    if (String(response?.status) == "200" && String(response.status) == "200") {
      var data = response.data.data;
      // Transform data to have value and label both as stateName
      const transformedData = data.map((state) => ({
        value: state.stateId,
        label: state.stateName,
      }));

      setStateList(transformedData);
    } else {
      toasterrormsg(response.message);
    }
  };

  useEffect(() => {
    stateListApiCall();
  }, []);

  const cityListApiCall = async () => {
    var param = {
      stateId: selectedState.value,
    };
    var response = await postData("/citylist", param, apiHeader(false));
    if (String(response?.status) == "200" && String(response.status) == "200") {
      var data = response.data.data;
      // Transform data to have value and label both as stateName
      const transformedData = data.map((city) => ({
        value: city.cityId,
        label: city.cityName,
      }));

      setCityList(transformedData);
    } else {
      toasterrormsg(response.message);
    }
  };

  useEffect(() => {
    if (selectedState) {
      cityListApiCall();
    }
  }, [selectedState]);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      color: "gray",
      fontSize: "18px",
      fontWeight: "400", // Ensure SemiBold weight is applied
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      boxShadow: "none",
      backgroundColor: "#f3f3f3",
      border: "none",
      width: "100%",
      fontWeight: "400",
      fontSize: "18px",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical line next to the dropdown icon
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "gray", // Customize the color of the selected value
      fontWeight: 100,
    }),
    menu: (provided) => ({
      ...provided,
      fontWeight: "500",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#333", // Different color when selected
      backgroundColor: state.isSelected ? "#007bff" : "#fff",
      fontFamily: "", // Apply Gilroy font to options
      fontWeight: "500",
      "&:hover": {
        backgroundColor: "#0096FF", // Background on hover
        color: "#fff",
      },
    }),
  };

  const variants2 = {
    hidden: { opacity: 0, x: -100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const variants4 = {
    hidden: { opacity: 0, x: 100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };
  return (
    <>
      <ToastContainer />

      <div className="text-center main_title_news careertitle mb-5 wow animate__animated animate__fadeInDown">
        <p className="text-center new_arrival_title">
          Cool Career Paths At <br /> Rangoli Ice Cream
        </p>
      </div>

      <div className="career_description container text-center wow animate__animated animate__fadeInUp">
        <p>
          At Rangoli Ice Cream, we believe in creating more than just a great
          delight experience – we believe in creating connections. From day one,
          we set out to build a company that brings people together, both in our
          stores and within our team. We're more than just a place to get an ice
          cream; we are the community where joy is shared with every
          interaction. We see our employees as an essential part of our journey,
          and that’s why we don’t just hire team members, but we welcome them
          into the Rangoli family. We are all partners in delivering happiness,
          and we do it with care, compassion, and a commitment to excellence. At
          Rangoli, our core purpose is simple:{" "}
          <span className="fw-bold">
            “We make People Joyful by Sharing Joy”{" "}
          </span>
          This purpose shines through in everything we do.
        </p>

        <p className="mt-5">
          Our culture is built on respect, diversity, and the idea that everyone
          contributes to making Rangoli a place where people feel they belong.
          Here, your passion and creativity will be valued, and you'll have the
          opportunity to grow and make an impact. <br /> Let’s create joyful
          experiences together – one scoop at a time amigo… <br /> Welcome
          aboard…!!!
        </p>
      </div>

      {isVisible ? (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants} // Apply the text animation
          className="d-flex justify-content-center main_title_news mt-5"
        >
          <p className="new_arrival_title text-center">
            Career Application Form
          </p>
        </motion.div>
      ) : (
        ""
      )}

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={animationVariants}
        className=" text-center question_form_title"
      >
        <p className="sub_title_form">
          Start Your Ice Cream Adventure - Apply Now !
        </p>
      </motion.div>

      <div className="container career_form">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-6">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                removeError("fullName");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].fullName}
            </div>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                removeError("email");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">{error[0].email}</div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row"
        >
          <div className="col-lg-6">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Phone Number"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                removeError("mobileNumber");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].mobileNumber}
            </div>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="w-100 connect_form_input"
              placeholder="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                removeError("address");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].address}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="row"
        >
          <div className="col-lg-6 col-sm-12">
            <Select
              className="connect_form_input w-100"
              styles={customStyles} // Apply custom styles
              placeholder="Select State"
              options={stateList}
              onChange={(e) => {
                setSelectedState(e);
                removeError("stateId");
              }}
            />
            <div className="text-danger  fs-5 mt-1 px-3">
              {error[0].stateId}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="connect_form_input d-flex justify-content-center">
              <Select
                className=" w-100"
                styles={customStyles} // Apply custom styles
                placeholder="Select City"
                options={cityList}
                onChange={(e) => {
                  setSelectedCity(e);
                  removeError("cityId");
                }}
              />
            </div>
            <div className="text-danger  fs-5 mt-1 px-3">{error[0].cityId}</div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="row"
        >
          <div className="d-flex justify-content-center mt-2">
            <motion.img
              key={resumeUrl}
              custom={resumeUrl}
              initial="hidden"
              animate="visible"
              variants={variants2}
              id="previewImage"
              style={{
                display: resumeUrl == "" ? "none" : "",
                borderRadius: "1rem",
              }}
              className="image-input-wrapper  w-100px changeimg pointer"
              src={
                resumeUrl
                  ? URL.createObjectURL(resumeUrl)
                  : resumeUrl === undefined ||
                    resumeUrl === "" ||
                    resumeUrl === null
                  ? toAbsoluteUrl("/media/default.jpg")
                  : resumeUrl
              }
            />
          </div>
          <div className="col-lg-12">
            <div
              className="connect_form_input d-flex justify-content-center align-items-center pt-3"
              onClick={handleClick} // Attach the click handler to the section
              style={{ cursor: "pointer" }} // Add pointer cursor to indicate clickability
            >
              <div
                style={{
                  width: "16px",
                  height: "8px",
                  borderBottom: "2px solid brown",
                  borderBottomRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="mb-3">
                  <FontAwesomeIcon icon={faArrowUp} color="brown" />
                </div>
                <input
                  type="file"
                  ref={fileInputRef} // Attach the reference to the file input
                  style={{ display: "none" }} // Hide the actual file input
                  onChange={(e) => {
                    if (
                      e.target.files[0]["type"] != "image/jpeg" &&
                      e.target.files[0]["type"] != "image/jpg" &&
                      e.target.files[0]["type"] != "image/png"
                    ) {
                      alert("Upload Only JPG, PNG Or PNG File");
                    } else if (
                      e.target.files[0] != null &&
                      e.target.files[0] != undefined
                    ) {
                      setResumeUrl(e.target.files[0]);
                    }
                  }}
                />
              </div>
              <div
                className="resume_text text-secondary"
                style={{ fontSize: "18px" }}
              >
                Upload Your Resume
              </div>
            </div>
          </div>

          <div className="text-danger  fs-5 mt-1 px-3">{error[0].resume}</div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants4}
          className="col-lg-12 mt-4"
        >
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
        </motion.div>
        <div className="text-danger  fs-5 mt-1 px-3">{error[0].captcha}</div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants5}
          className="form_button"
        >
          <button
            onClick={() => {
              carrerForm();
            }}
          >
            Submit
          </button>
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

const Career = () => {
  return <Header element={<CareerWrapper />} />;
};

export { Career };
