import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import NumberCounter from "./components/NumberCounter";
import { motion } from "framer-motion";

const PrivacyWrapper = () => {
  const [counter, setCounter] = useState();
  const [direction, setDirection] = useState(-300); // Initial direction from the left
  const [isVisible, setIsVisible] = useState(false);
  const [showNewImage, setShowNewImage] = useState(false);

  useEffect(() => {
    // Set a timeout to change the visibility after 0.8 seconds
    setIsVisible(true);

    const hideTimer = setTimeout(() => {
      setIsVisible(false);
      setShowNewImage(true);
    }, 2700); // Adjust timing for smoother transition

    // Cleanup timers if the component unmounts
    return () => {
      clearTimeout(hideTimer);
    };
  }, []);

  useEffect(() => {
    if (counter === 4) {
      setDirection(300); // Switch direction to the right
    }
  }, [counter]);

  const variants2 = {
    hidden: { opacity: 0, x: 100 }, // Start from the right
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the center
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variants3 = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  // Define animation variants for the initial images
  const variants6 = {
    hidden: { opacity: 0, x: -200 }, // Off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Moves to its original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Smooth spring animation
        stiffness: 50,
        damping: 20,
      },
    }),
    exit: {
      opacity: 0, // Fade out
      scale: 0.5, // Shrink the image
      transition: {
        duration: 0.8, // Duration of fade and shrink
        ease: "easeInOut", // Smooth ease
      },
    },
  };

  const variants7 = {
    hidden: { opacity: 0, x: 200 }, // Off-screen to the right
    visible: (i) => ({
      opacity: 1,
      x: 0, // Moves to its original position
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
    exit: {
      opacity: 0, // Fade out
      scale: 0.5, // Shrink the image
      transition: {
        duration: 0.8, // Duration of fade and shrink
        ease: "easeInOut", // Smooth ease
      },
    },
  };

  // Define animation for the new image
  const newImageVariant = {
    hidden: { opacity: 0, scale: 0.5 }, // Starts small and hidden
    visible: {
      opacity: 1,
      scale: 1, // Pops to full size
      transition: {
        duration: 0.6, // Slight delay before popping in
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    },
  };
  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants2} // Apply the text animation
        className="d-flex justify-content-center main_title_news"
      >
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="new_arrival_title text-center"
        >
          Privacy Policy
        </motion.p>
      </motion.div>

      <div className="career_description container ">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          At Rangoli Ice Cream, your privacy is important to us. This Privacy
          Policy explains how we collect, use, and protect your personal
          information when you interact with our website or use our services. By
          using our site, you agree to the terms outlined in this policy.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          We may collect personal information that you provide directly to us,
          such as your name, email address, phone number, and payment details.
          Additionally, when you visit our website, we may automatically collect
          certain data, including your IP address, browser type, and device
          information, to enhance your browsing experience.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          To improve functionality and analyze website performance, we use
          cookies and similar technologies. These tools help us understand user
          preferences and tailor our content accordingly. You can adjust your
          browser settings to limit or disable cookies, but some features of our
          site may not function properly as a result.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          We use the information collected to provide and improve our services,
          process transactions, and communicate updates or promotional offers.
          Rest assured, we do not share your personal information with third
          parties except when required by law or to fulfill a service you've
          requested.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          Our website may contain links to third-party sites. We are not
          responsible for the privacy practices of these external platforms, so
          we encourage you to review their policies before sharing your
          information.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          We prioritize the security of your personal data and employ
          industry-standard measures to protect it from unauthorized access or
          misuse. However, no system is completely secure, and we cannot
          guarantee the absolute security of your data.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          If you have any questions or concerns about this Privacy Policy,
          please contact us at [Your Contact Email]. We reserve the right to
          update this policy periodically, and any changes will be reflected on
          this page.
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          Thank you for trusting Rangoli Ice Cream.
        </motion.p>
      </div>

      <Footer />
    </>
  );
};

const PrivacyPolicy = () => {
  return <Header element={<PrivacyWrapper />} />;
};

export { PrivacyPolicy };
