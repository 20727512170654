
import React from "react";
import loader from  '../../assets/images/loadergif.gif'

const PreLoader = () => {
  return (
    <div className="preloader" id="loader">
      <div className="container">
        
        <img src={loader} width={"100%"} alt="Loading..." />
      </div>
    </div>
  );
};

export default PreLoader;
